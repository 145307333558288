export default {
  colors: {
    text: "#111",
    background: "#fff",
    primary: "#111",
    accent: "#fff",
    muted: "rgba(0, 0, 0, 0.7)",
    cardBg: "#fff",
    borderColor: "#540229",
    labelText: "#777",
    inputBorder: "#aaa",
    inputBackground: "#fff",
    buttonAccent: "#00b3c0",
    modes: {
      dark: {
        text: "#f5f5f5",
        background: "#111",
        primary: "#252525",
        accent: "#333",
        muted: "rgba(255, 255, 255, 0.7)",
        buttonAccent: "yellow",
        cardBg: "#252525",
        borderColor: "#888",
        labelText: "#777",
        inputBorder: "#777",
        inputBackground: "#333",
      },
    },
  },
  links: {
    postLink: {
      color: "muted",
      "&:hover": {
        color: "text",
      },
    },
    button: {
      bg: "buttonAccent",
      color: "accent",
    },
  },
}
